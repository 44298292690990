import React, {FC, useCallback, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Collapse, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {Input} from '@/components/UIKit';
import {DeleteTelegramLinkChatConfirmation} from '@/modals/confirmation/DeleteTelegramLinkChatConfirmation';
import {IChat} from '@/shared/models/chatModel.ts';
import {ChatInfoLoadingButton} from '@/shared/styles/chatStyles';
import {useUpdateChatMutation} from '@/stores/api/chats.ts';
import {useAppSelector} from '@/stores/hooks.ts';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';
import {inviteLinkPattern} from '@/shared/constants/telegramConstants.ts';

interface IProps {
  chatData?: IChat;
}

const ChatInfoTelegram: FC<IProps> = ({chatData}) => {
  const currentUser = useAppSelector(userInfoSelector);
  const [submitUpdateChat, {isLoading: isUpdateLoading}] = useUpdateChatMutation();
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const [isOpenTelegram, setIsOpenTelegram] = useState<boolean>(false);
  const [telegramInviteLinkValue, setTelegramInviteLinkValue] = useState<string | undefined>(
    undefined
  );
  const [inviteLinkError, setInviteLinkError] = useState<string | null>(null);

  const isDisabledButton = !currentUser?.isHead && !currentUser?.isHeadUnit;
  const isDisabledInput = isDisabledButton || !!chatData?.telegramInviteLink;

  const handleChangeTelegramInviteLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTelegramInviteLinkValue(value);

    if (!value) {
      setInviteLinkError(null);
      return;
    }

    if (!inviteLinkPattern.test(value)) {
      setInviteLinkError('Укажите ссылку в формате: https://t.me/+ABC123');
    } else {
      setInviteLinkError(null);
    }
  };

  useEffect(() => {
    if (chatData?.telegramInviteLink) {
      setTelegramInviteLinkValue(chatData.telegramInviteLink);
    } else {
      setTelegramInviteLinkValue('');
    }
  }, [chatData?.telegramInviteLink]);

  const handleUpdate = useCallback(async () => {
    if (!chatData?.id) {
      console.warn('chatId не найден');
      return;
    }

    if (inviteLinkError) {
      return;
    }

    const params = {
      chatId: String(chatData.id),
      telegram_invite_link: telegramInviteLinkValue
    };

    const isEmpty = Object.keys(params).filter(key => key !== 'chatId').length === 0;
    if (!isEmpty) {
      await submitUpdateChat(params);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData, telegramInviteLinkValue, inviteLinkError]);

  const handleDeleteSubmitConfirmation = useCallback(async () => {
    if (!chatData?.id) {
      console.warn('chatId не найден');
      return;
    }

    const params = {
      chatId: String(chatData.id),
      telegram_invite_link: null
    };

    const isEmpty = Object.keys(params).filter(key => key !== 'chatId').length === 0;
    if (!isEmpty) {
      await submitUpdateChat(params);
    }

    handleDeleteCloseConfirmation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData]);

  const handleDeleteCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  return (
    <>
      <Stack padding={6}>
        <Button
          variant={'contained'}
          color={'secondary'}
          onClick={() => setIsOpenTelegram(prevState => !prevState)}
        >
          <Typography variant="h2">Телеграм</Typography>
        </Button>
        {!chatData && (
          <Stack padding={6} alignItems={'center'}>
            <CircularProgress color="inherit" />
          </Stack>
        )}
        <Collapse in={isOpenTelegram} timeout="auto" unmountOnExit>
          <Stack
            direction="row"
            paddingTop={10}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box flexBasis="80%">
              <Input
                fullWidth
                autoComplete="off"
                value={telegramInviteLinkValue}
                onChange={handleChangeTelegramInviteLink}
                label="Пригласительная ссылка"
                disabled={isDisabledInput}
                error={!!inviteLinkError}
                helperText={inviteLinkError}
                FormHelperTextProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    minHeight: '20px',
                    marginTop: '4px'
                  }
                }}
              />
            </Box>
            <Box flexBasis="30%" marginLeft="5%">
              {!chatData?.telegramInviteLink && (
                <ChatInfoLoadingButton
                  variant={'contained'}
                  color={'info'}
                  fullWidth
                  onClick={handleUpdate}
                  loading={isUpdateLoading}
                  loadingPosition="center"
                  disabled={isDisabledButton || !!inviteLinkError}
                >
                  <Typography variant="body1" sx={{fontSize: '12px'}}>
                    Добавить
                  </Typography>
                </ChatInfoLoadingButton>
              )}
              {chatData?.telegramInviteLink && (
                <LoadingButton
                  variant={'contained'}
                  color={'error'}
                  onClick={() => setOpenConfirmation(prevState => !prevState)}
                  fullWidth
                  disabled={isDisabledButton}
                >
                  <Typography variant="body1" sx={{fontSize: '12px'}}>
                    Удалить
                  </Typography>
                </LoadingButton>
              )}
            </Box>
          </Stack>
        </Collapse>
      </Stack>
      <DeleteTelegramLinkChatConfirmation
        open={openConfirmation}
        onSubmit={handleDeleteSubmitConfirmation}
        onClose={handleDeleteCloseConfirmation}
      />
    </>
  );
};

export default ChatInfoTelegram;
