import {useEffect, useMemo} from 'react';

import {useFetchCompaniesMutation, useGetCompanyByIdQuery} from '@/stores/api/companies';
import {useResponsiblesFilters} from '@/modals/create/hooks/useResponsiblesFilters';
import {useAppSelector} from '@/stores/hooks';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {allDepartmentsQueryState} from '@/stores/api/dictionaries';
import {ICommonArgs} from '@/shared/models/commonModel';

interface IProps {
  defaultCompanyId?: number;
  setValue?: any;
  queryParams?: Record<string, string | number>;
  skip?: boolean;
}

export const useCompanyLogic = ({
  defaultCompanyId,
  setValue,
  queryParams,
  skip = false
}: IProps) => {
  const currentUser = useAppSelector(userInfoSelector);
  const {data: departmentsDict = []} = allDepartmentsQueryState({});

  const {data: company} = useGetCompanyByIdQuery(
    {
      companyId: defaultCompanyId
    },
    {
      skip: !defaultCompanyId || skip
    }
  );

  const responsiblesFilters = useResponsiblesFilters();

  const query = useMemo(() => {
    let params: ICommonArgs = {
      filters: {
        active: 1
      },
      page: {
        limit: 50,
        offset: 0
      },
      ...queryParams
    };

    const userDepartments = currentUser.userDepartments || [];
    const isCrossUnitUser = userDepartments.filter(val => {
      return (
        departmentsDict.findIndex(({id, isCrossUnit}) => isCrossUnit && id === val.departmentId) >
        -1
      );
    });

    // Если отдел не кросс юнитовый то у обычного бухгалтера выводятся клиенты где он подчинённый то есть используется фильтр has_responsibles
    if (isCrossUnitUser.length === 0) {
      params = {
        ...params,
        'filters[company_type_id]': 1,
        ...responsiblesFilters
      };
    }

    // для crossunit пользователя вывести список всех компаний
    if (isCrossUnitUser.length > 0) {
      params = {
        ...params,
        'filters[company_type_id]': 1
      };
    }

    return params;
  }, [currentUser.userDepartments, departmentsDict, queryParams, responsiblesFilters]);

  const [fetchCompanies, {isLoading: isCompaniesLoading}] = useFetchCompaniesMutation();

  useEffect(() => {
    if (company) {
      setValue('company', company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return {
    fetchCompanies,
    query,
    isCompaniesLoading
  };
};
